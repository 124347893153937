import type { ChangeEvent, ReactNode } from 'react'
import castArray from 'lodash.castarray'
import { useFormContext } from 'react-hook-form'
import classnames from 'classnames'
import get from 'lodash.get'

interface CheckboxInputProps {
  label: string | ReactNode
  name: string
  error?: string | string[]
  defaultChecked?: boolean // Uncontrolled: initial checked state
  checked?: boolean // Controlled: `checked` prop
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
}

// This component can work in both a controlled and uncontrolled manner.
//
// This is because it's common to want to use a checkbox outside of a standard
// form - in that case you would want to be able to control the state of the
// checkbox directly.
//
// Passing a `checked` prop will make the checkbox controlled, otherwise it will
// be uncontrolled.
//
// Integration with react-hook-form is also supported, but only works in an
// uncontrolled manner.
const CheckboxInput = ({
  label,
  name,
  onChange,
  defaultChecked = false, // Uncontrolled: initial checked state
  checked, // Controlled: controlled checked state
  error,
  className
}: CheckboxInputProps) => {
  const formContext = useFormContext()
  const register = formContext ? formContext.register : undefined
  const { errors: contextErrors = {} } = formContext?.formState || {}

  const contextError = get(contextErrors, name)?.message as string | undefined
  const wrapContextError = contextError ? castArray(contextError) : []
  const errors = (error ? castArray(error) : []).concat(wrapContextError)

  // Register the input with Remix Hook Form if uncontrolled
  const registeredAttrs = register?.(name, { onChange }) || { name, onChange }

  const tick =
    "checked:before:absolute checked:before:content-['✔'] checked:before:text-dark checked:before:text-lg checked:before:flex checked:before:items-center checked:before:justify-center"

  return (
    <div className={className}>
      <div className="flex items-center">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className={classnames(
              'mr-2 flex h-6 w-6 flex-shrink-0 appearance-none items-center justify-center ring-2 ring-light checked:bg-surfaceOrange',
              tick
            )}
            // Conditionally handle controlled vs uncontrolled behavior:
            // Use `checked` if provided (controlled), otherwise rely on `defaultChecked` and RHF (uncontrolled).
            {...(checked !== undefined
              ? { checked, onChange } // Controlled mode
              : { ...registeredAttrs, defaultChecked })} // Uncontrolled with RHF
          />
          <span className="text-sm">{label}</span>
        </label>
        <span className="flex-grow">&nbsp;</span>
      </div>
      {errors?.map(
        (error, index) =>
          error && (
            <p className="text-basePink" key={index}>
              {error}
            </p>
          )
      )}
    </div>
  )
}

export default CheckboxInput
