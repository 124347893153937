import classnames from 'classnames'

import type { PartialButtonLinkProps } from '~/components/ButtonLink'
import ButtonLink from '~/components/ButtonLink'
import Spinner from '~/components/Spinner'

type AccountActionPromptProps = {
  className?: string
  description?: string
  actionText?: string
  actionUrl?: string
  onActionClick?: () => void
  isLoading?: boolean
}

export const AccountActionPrompt = ({
  description = 'Already have an account?',
  actionText = 'Log in',
  className,
  actionUrl = '/cub-club/login',
  onActionClick,
  isLoading
}: AccountActionPromptProps) => {
  const buttonLinkProps: PartialButtonLinkProps = onActionClick
    ? { as: 'button', onClick: onActionClick }
    : { as: 'link', to: actionUrl, prefetch: 'intent' }

  return (
    <div className={classnames('mt-4 flex flex-1 flex-row items-center justify-center space-x-1', className)}>
      <span className="text-baseGray dark:text-light">{description}</span>
      <ButtonLink {...buttonLinkProps} isDisabled={isLoading}>
        {actionText}
      </ButtonLink>
      {isLoading && <Spinner d={4} />}
    </div>
  )
}

export default AccountActionPrompt
