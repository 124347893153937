import React from 'react'
import { Link } from '@remix-run/react'
import classnames from 'classnames'

type CommonProps = {
  children: React.ReactNode
  className?: string
  isDisabled?: boolean
}

type ButtonProps = {
  as: 'button'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'button' | 'submit' | 'reset'
} & CommonProps

type LinkProps = {
  as: 'link'
  to: string
  prefetch?: 'intent' | 'render' | 'none'
} & CommonProps

export type ButtonLinkProps = ButtonProps | LinkProps

// Helper type for partial props that preserves the discriminated union
export type PartialButtonLinkProps =
  | (Omit<ButtonProps, 'as' | 'children'> & { as: 'button' })
  | (Omit<LinkProps, 'as' | 'children'> & { as: 'link' })

const ButtonLink = ({ isDisabled, as, children, className = '', ...props }: ButtonLinkProps) => {
  const linkStyles = classnames(
    'px-1 py-0.5 font-bold text-baseBlue hover:bg-opacity-50 hover:underline',
    isDisabled && 'pointer-events-none text-mid cursor-not-allowed'
  )

  if (as === 'button') {
    const { type = 'button', onClick, ...rest } = props as ButtonProps
    return (
      <button type={type} className={`${linkStyles} ${className}`} onClick={onClick} disabled={isDisabled} {...rest}>
        {children}
      </button>
    )
  }

  const { to, prefetch = 'intent', ...rest } = props as LinkProps
  return (
    <Link to={to} prefetch={prefetch} className={`${linkStyles} ${className}`} {...rest}>
      {children}
    </Link>
  )
}

export default ButtonLink
